import { cn } from "../../lib/utils";
import { Progress } from "../ui/progress";
import { Check } from "lucide-react";
import Rating from "./rating";
import { Link } from "react-router-dom";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import {
    PlaybookStage,
} from "tekkr-common/dist/model/playbook/enums/enums";
import { playbookStageTitles } from "tekkr-common/dist/model/playbook/static/copy";
import React from "react";
import { PlaybookState } from "tekkr-common/dist/model/playbook/state";
import { IconForPlaybookStage } from "./icon-playbook-stage";
import { formats } from "tekkr-common/dist/utils/formatting";
import { PlaybookCategoryBadges } from "./playbook-category-badges";
import { useOrg } from "../../auth/org-provider";
import { AvatarForUser } from "./avatar-for-user";

export type PlaybookMeta = ClientInferResponseBody<typeof apiContract.listPlaybooks, 200>["data"][0];

interface PlaybookCardProps {
    variant: "vertical" | "horizontal";
    data: PlaybookMeta;
    state: PlaybookState;
    linkTo?: string;
}

function TransitionStagePlaybookStateFooter(props: { stage: PlaybookStage }) {
    return <div className={"text-sm flex flex-row gap-1.5 items-center"}>
        <div className={"me-1 text-muted-foreground"}>Next:</div>
        <IconForPlaybookStage stage={props.stage} className={"inline-block w-4 h-4"} />
        <div>{playbookStageTitles.upcoming[props.stage]}</div>
    </div>;
}

function ProgressBarPlaybookStateFooter(props: { stage: PlaybookStage, progress: number }) {
    return <div className={"flex flex-row gap-3 items-center text-sm"}>
        <div className={"flex flex-row gap-1.5 items-center text-muted-foreground"}>
            <IconForPlaybookStage stage={props.stage} className={"inline-block shrink-0 w-4 h-4"} />
            <div className={"shrink-0"}>{playbookStageTitles.ongoing[props.stage]}:</div>
        </div>
        <Progress
            value={props.progress * 100}
            className={"h-2 shrink grow w-auto"}
            color={"confirmation"}
        ></Progress>
        <div className={"flex-shrink-0"}>
            {Math.round((props.progress) * 100)}%
        </div>
    </div>;
}

function PlaybookStateFooter(props: { state: PlaybookState }) {
    const firstNonCompleted = Object.values(PlaybookStage).find(stage => {
        return props.state[stage] !== undefined && props.state[stage]! < 1;
    })

    if (!firstNonCompleted) {
        return <div className={"font-semibold flex flex-row items-center gap-2 text-sm text-confirmation"}><Check className={"w-4 h-4"} /> completed</div>
    }

    if (props.state[firstNonCompleted] === 0) {
        return <TransitionStagePlaybookStateFooter stage={firstNonCompleted} />
    } else {
        return <ProgressBarPlaybookStateFooter stage={firstNonCompleted} progress={props.state[firstNonCompleted]!} />
    }
}

function UserHighlight (props: { userIds: string[] }) {
    const relevantIds = new Set(props.userIds.slice(0, 3));
    const org = useOrg();
    const users = org.users.filter(u => relevantIds.has(u.id));

    return <div className={"flex flex-row items-center text-sm font-medium gap-2"}>
        <div className={"flex flex-row shrink-0 ps-1.5"}>
            { users.map(u => <AvatarForUser key={u.id} user={u} className={"w-6 h-6 -ms-1.5 outline outline-background"} />) }
        </div>
        <div className={"grow line-clamp-1"}>{ formats.friendly.commaList(users.map(u => u.name)) }</div>
    </div>
}

function PlaybookCard(props: PlaybookCardProps) {
    const highlightUsers = [
        props.data.contributors.owner,
    ];
    if (props.data.contributors.driver) {
        highlightUsers.push(props.data.contributors.driver);
    }

    const card = (
        <div
            data-testid="playbook-card"
            className={cn(
                "border bg-background relative rounded-lg p-4 hover:bg-accent transition-all overflow-hidden group animate-in fade-in slide-in-from-bottom-3 duration-300",
                props.variant === "vertical" ? "min-w-48" : null,
                //`duration-[${}]`
            )}
        >
            <div
                className={cn(
                    "flex gap-4",
                    props.variant === "horizontal" ? "flex-row" : "flex-col",
                )}
            >
                <img
                    alt={"Playbook Cover"}
                    className={cn(
                        "border-0 rounded-lg object-cover aspect-3/2 bg-accent",
                        props.variant === "horizontal" ? "w-1/3 shrink-0" : null,
                    )}
                    src={props.data.imageUrl}
                />
                <div className={cn("flex flex-col gap-2", props.variant === "vertical" ? "h-64" : undefined)}>
                    <div>
                        <PlaybookCategoryBadges categories={props.data.categories} />
                        <h4 className={cn("line-clamp-3 mt-2")}>{props.data.title}</h4>
                    </div>
                    <UserHighlight userIds={highlightUsers} />
                    <p className={"shrink overflow-ellipsis overflow-hidden line-clamp-5"}>
                        {props.data.summary}
                    </p>
                    <div className={"grow"}></div>
                    <hr />
                    {props.state !== undefined && <PlaybookStateFooter state={props.state} />}
                </div>
            </div>
        </div>
    );

    return <Link to={props.linkTo ?? `/playbook/${props.data.id}`}>{card}</Link>;
}

export default PlaybookCard;
