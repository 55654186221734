import React, { createContext, useContext, useEffect, useMemo, useRef } from "react";
import { apiClient, ApiError } from "../../service/tekkr-service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Spinner from "../../components/ui/spinner";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { InternalErrorPageContent, NotFoundErrorPageContent } from "../../components/shared/error-message";
import PlaybookEditPage from "./stages/edit";
import { PlaybookStage } from "tekkr-common/dist/model/playbook/enums/enums";
import { PlaybookSidebar, PlaybookSidebarNavigation } from "./sidebar";
import { PlaybookProvider, usePlaybook } from "./controller/hooks/playbook";
import PlaybookIntroDialog from "../../modals/playbook-intro-dialog";
import { PlaybookStageIntroPage } from "./stages/stage-intro-page";
import { TargetsSettingPage } from "./stages/targets/targets-setting-page";
import { ScheduleTargetReviewsPage } from "./stages/targets/schedule-target-reviews-page";
import { CommunicateTargetsPage } from "./stages/targets/communicate-targets-page";
import { AlignmentRoastingPage } from "./stages/alignment/roasting-page";
import { playbookQueries } from "./controller/queries";
import { AlignmentReviewPage } from "./stages/alignment/playbook-review-page";
import { ImplementationTodosPage } from "./stages/implementation/todos-page";
import { getPlaybookNav } from "./controller/navigation";
import { InformStakeholdersCommunicationPage } from "./stages/communicate/inform-stakeholders-communication-page";
import { AnnouncementCommunicationPage } from "./stages/communicate/announcement-communication-page";
import { computePlaybookEditProgress } from "tekkr-common/dist/model/playbook/state";
import { cn } from "../../lib/utils";
import { useCurrentOrgUser } from "../../auth/org-provider";
import { ContentSpacer } from "../common/layout";
import { TrackMetricsPage } from "./stages/track/track-metrics-page";
import { PlaybookEditCompletedDialog } from "../../modals/playbook-edit-completed-dialog";
import { BlueprintProvider, useBlueprint } from "./controller/hooks/blueprint";
import { Button } from "../../components/ui/button";
import { BookMarked, PenIcon } from "lucide-react";
import { TooltipTrigger } from "../../components/ui/tooltip";
import { PlaybookTooltip } from "../../components/shared/playbook-tooltip";
import { PlaybookBlueprint } from "tekkr-common/dist/model/playbook/blueprint";
import { PlaybookEdit } from "tekkr-common/dist/model/playbook/edit";
import { PlaybookReviewHoldPage } from "./stages/alignment/playbook-review-hold-page";
import { PlaybookWrapper } from "tekkr-common/dist/lib/playbook-wrapper";

export interface PlaybookPageNavigationState {
    openEditMode?: boolean;
}

interface NavPosition {
    stage: PlaybookStage;
    step?: string;
    stepId?: string;
}

function PlaybookPageNavContent({ navPosition }: { navPosition: NavPosition }) {
    if (navPosition.stage === PlaybookStage.edit) {
        return <PlaybookEditPage />
    }
    if (navPosition.stage === PlaybookStage.targets) {
        if (!navPosition.step) {
            return <PlaybookStageIntroPage key={PlaybookStage.targets} stage={PlaybookStage.targets} />
        } else if (navPosition.step === "draft") {
            return <TargetsSettingPage variant={"draft"} />
        } else if (navPosition.step === "set") {
            return <TargetsSettingPage variant={"set"} />
        } else if (navPosition.step === "finalize") {
            return <TargetsSettingPage variant={"finalize"} />
        } else if (navPosition.step === "schedule-review") {
            return <ScheduleTargetReviewsPage targetReviewId={navPosition.stepId!} />
        } else if (navPosition.step === "communicate") {
            return <CommunicateTargetsPage />
        }
    }
    if (navPosition.stage === PlaybookStage.alignment) {
        if (!navPosition.step) {
            return <PlaybookStageIntroPage key={PlaybookStage.alignment} stage={PlaybookStage.alignment} />
        } else if (navPosition.step === "roasting") {
            return <AlignmentRoastingPage roastingId={navPosition.stepId!} />
        } else if (navPosition.step === "schedule-review") {
            return <AlignmentReviewPage reviewId={navPosition.stepId!} />
        } else if (navPosition.step === "run-reviews") {
            return <PlaybookReviewHoldPage />
        }
    }
    if (navPosition.stage === PlaybookStage.implementation) {
        if (!navPosition.step) {
            return <PlaybookStageIntroPage key={PlaybookStage.implementation} stage={PlaybookStage.implementation} />
        } else if (navPosition.step === "todos") {
            return <ImplementationTodosPage />
        }
    }
    if (navPosition.stage === PlaybookStage.communication) {
        if (!navPosition.step) {
            return <PlaybookStageIntroPage key={PlaybookStage.communication} stage={PlaybookStage.communication} />
        } else if (navPosition.step === "inform-stakeholders") {
            return <InformStakeholdersCommunicationPage />
        } else if (navPosition.step === "announce") {
            return <AnnouncementCommunicationPage />
        }
    }
    if (navPosition.stage === PlaybookStage.tracking) {
        if (!navPosition.step) {
            return <PlaybookStageIntroPage key={PlaybookStage.tracking} stage={PlaybookStage.tracking} />
        } else if (navPosition.step === "metrics") {
            return <TrackMetricsPage />
        }
    }
    return <></>
}

interface PlaybookPageNavigation {
    goToNext(): void;
    deletePlaybook(): void;
    showEditCompletedDialog(): void;
}
const PlaybookPageNavigationContext = createContext<PlaybookPageNavigation | null>(null);

export function usePlaybookPageNavigation(): PlaybookPageNavigation | null {
    return useContext(PlaybookPageNavigationContext);
}


function getAutoNavigateStage(pbEdit: PlaybookEdit | undefined, blueprint: PlaybookBlueprint, navigation: PlaybookSidebarNavigation): SetStageInput {
    const editProgress = computePlaybookEditProgress(blueprint, pbEdit);
    if (editProgress < 1.0) {
        return { stage: PlaybookStage.edit };
    }
    const stage = navigation.stages.find(s => s.state === "todo");
    if (!stage) {
        return {
            stage: PlaybookStage.edit,
        } ;
    } else if (!stage.steps) {
        return {
            stage: stage.stage,
        } ;
    } else {
        // @ts-expect-error something's wrong with the compiler here
        for (const step of stage.steps.values()) {
            if (step.state === "todo") {
                return {
                    stage: stage.stage,
                    step: step.step,
                    stepId: step.id,
                };
            }
        }
    }
    return {
        stage: PlaybookStage.edit,
    }
}

type SetStageInput = { stage: PlaybookStage, step?: string, stepId?: string };

function PlaybookPage() {
    const { playbookId } = useParams();
    if (!playbookId) {
        throw new Error("missing playbook id");
    }
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const deleteMutation = useMutation({
        mutationFn: async () => {
            await (await apiClient).deletePlaybook({
                params: { playbookId },
                body: undefined,
            });
            await queryClient.invalidateQueries({
                queryKey: ["library-playbooks"],
            });
            navigate("/library");
        },
    });

    const { isError, error, data, refetch: refetchPlaybook } = useQuery(playbookQueries.getPlaybook(playbookId));
    const pw = useMemo(() => data ? new PlaybookWrapper(data.playbook, data.blueprint) : null, [data]);

    const [query, setQuery] = useSearchParams();
    const navPosition: NavPosition = {
        stage: query.get("view") as PlaybookStage ?? undefined,
        step: query.get("step") ?? undefined,
        stepId: query.get("step_id") ?? undefined,
    }
    const setStage = (selected: SetStageInput, replace: boolean = false) => {
        if (selected.stage === navPosition.stage && selected.step === navPosition.step && selected.stepId === navPosition.stepId) {
            return; // do nothing, we're already here.
        }
        query.set("view", selected.stage);
        if (selected.step) {
            query.set("step", selected.step);
        } else {
            query.delete("step");
        }
        if (selected.stepId) {
            query.set("step_id", selected.stepId);
        } else {
            query.delete("step_id");
        }
        setQuery(query, {
            replace: replace,
        });
    };

    let content = (
        <div className={"w-full flex flex-col items-center mt-12 animate-in slide-in-from-bottom-2 fade-in"}>
            <Spinner className={"w-8 h-8"}></Spinner>
            <div className={"mt-4 text-sm text-secondary-foreground animate-in fade-in-50 slide-in-from-bottom-2 duration-500"}>Loading Playbook...</div>
        </div>
    );

    const currentUserId = useCurrentOrgUser().id;
    const navigationOptional = pw ? getPlaybookNav(pw, currentUserId) : null;
    const autoNavPosition = !!data && navigationOptional ? getAutoNavigateStage(data!.playbook?.edit, data!.blueprint, navigationOptional) : undefined;

    useEffect(() => {
        if (navigationOptional && !navPosition.stage) {
            setStage({ stage: PlaybookStage.edit }, true)
        }
    }, [navigationOptional, navPosition.stage]);


    const [showEditCompleteDialog, setShowEditCompleteDialog] = React.useState(false);

    if (isError) {
        if (error instanceof ApiError && error.status === 404) {
            content = <ContentSpacer><NotFoundErrorPageContent /></ContentSpacer>;
        } else {
            content = <ContentSpacer><InternalErrorPageContent /></ContentSpacer>;
        }
    } else if (deleteMutation.isPending) {
        content = <div className={"flex flex-row items-center justify-center gap-2 font-semibold"}><Spinner /> Deleting
            Playbook...</div>;
    } else if (data) {
        if (!data.playbook.peopleGroups) {
            data.playbook.peopleGroups = {};
        }
        const navigation = navigationOptional!;

        const navContext: PlaybookPageNavigation = {
            goToNext() {
                // push all steps to a stack
                const stack: NavPosition[] = [];
                for (const stage of navigation.stages) {
                    stack.push({ stage: stage.stage });
                    for (const step of stage.steps ?? []) {
                        if (!step.blocked) {
                            stack.push({ stage: stage.stage, step: step.step, stepId: step.id });
                        }
                    }
                }

                // find current and go to next
                let found: boolean = false;
                for (const cur of stack) {
                    if (found) {
                        return setStage(cur);
                    } else if (cur.stage === navPosition.stage && cur.step === navPosition.step && cur.stepId === navPosition.stepId) {
                        found = true;
                    }
                }
                return setStage(stack[0]);
            },
            showEditCompletedDialog() {
                setShowEditCompleteDialog(true);
            },
            deletePlaybook() {
                deleteMutation.mutate();
            },
        }

        const introOpen = query.get("new_playbook") === "true";
        const closeIntro = () => {
            query.delete("new_playbook");
            setQuery(query);
        }

        const introDialogStages = new Set<PlaybookStage>();
        for (const stage of navigation.stages) {
            introDialogStages.add(stage.stage);
        }

        const editDone = computePlaybookEditProgress(data.blueprint, data.playbook.edit) === 1.0;

        content = <BlueprintProvider blueprint={data.blueprint}>
            <PlaybookProvider data={{ ...data, refetch: refetchPlaybook }}>
                <PlaybookPageNavigationContext.Provider value={navContext}>
                    <PlaybookEditCompletedDialog open={showEditCompleteDialog} nav={navigation}
                                                 onOpenChange={setShowEditCompleteDialog} />
                    <PlaybookIntroDialog open={introOpen} onOpenChange={closeIntro} stages={introDialogStages}
                                         playbookTitle={data.playbook.edit?.title ?? data.blueprint.title} />
                    <div className={"flex flex-col grow"}>
                        { editDone && <PlaybookTitleBar collapsed={!navPosition.stage || navPosition.stage === PlaybookStage.edit} onGoToPlaybook={() => setStage({ stage: PlaybookStage.edit })} /> }
                        <div className={"grow flex flex-row"}>
                            {editDone &&
                                <PlaybookSidebar recommendedPosition={autoNavPosition?.stage !== PlaybookStage.edit ? autoNavPosition : undefined} nav={navigation} selected={navPosition} onSelect={setStage} />}
                            <div className={"grow items-stretch"}>
                                {<ContentSpacer contentWidth={"narrower"}>
                                    <PlaybookPageNavContent navPosition={navPosition} />
                                </ContentSpacer>}
                            </div>
                        </div>
                    </div>
                </PlaybookPageNavigationContext.Provider>
            </PlaybookProvider>
        </BlueprintProvider>;
    }
    return content;
}

function PlaybookTitleBar (props: {
    onGoToPlaybook: () => void;
    collapsed: boolean;
}) {
    const { playbook } = usePlaybook();
    const { blueprint } = useBlueprint();

    const barRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const onScroll = () => {
            if (!barRef.current) {
                return;
            }
            const offset = document.documentElement.scrollTop;
            barRef.current.style.transform = `translateY(${Math.pow(offset, 0.8)}px)`;
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return <PlaybookTooltip data={{
        title: playbook.edit?.title ?? blueprint.title,
        summary: blueprint.summary,
        imageUrl: blueprint.imageUrl,
        createdOn: new Date(playbook.createdAt),
    }}>
        <div ref={barRef}
             style={{ transition: "max-height .3s ease-in-out"}}
            className={cn("h-14 max-h-14 w-full bg-accent shrink-0 justify-center flex flex-row items-center overflow-hidden gap-6", props.collapsed && "max-h-0")}>
            <TooltipTrigger asChild>
                <div className={"cursor-default flex flex-row items-center gap-2 font-semibold"}>
                    <BookMarked
                        className={"w-4 h-4"} /> {playbook.edit?.title ?? blueprint.title}
                </div>
            </TooltipTrigger>
            <Button variant={"secondary"} size={"sm"}
                    onClick={props.onGoToPlaybook}>
                <PenIcon className={"w-4 h-4 me-2"} />
                Go to Playbook
            </Button>
        </div>
    </PlaybookTooltip>
}

export default PlaybookPage;