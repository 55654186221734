"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.playbookBlueprintSchema = void 0;
const base_1 = require("./base");
const blueprint_1 = require("./segment/blueprint");
const zod_1 = require("zod");
const enums_1 = require("./enums/enums");
const schema_refinements_1 = require("../../utils/schema-refinements");
const target_review_1 = require("./stages/target-review");
const alignment_1 = require("./stages/alignment");
const people_groups_1 = require("./people-groups");
const iterate_object_paths_1 = require("../../utils/iterate-object-paths");
const communication_1 = require("./stages/communication");
const blueprint_2 = require("./stages/implementation/blueprint");
const peopleGroupPaths = [
    "segments.*.content.*.peopleGroupId",
    "targetReview.expectationReviews.*.peopleGroup",
    "targetReview.communication.peopleGroups.*",
    "alignment.playbookReviews.*.peopleGroup",
];
exports.playbookBlueprintSchema = base_1.basePlaybookSchema.extend({
    state: zod_1.z.nativeEnum(enums_1.PlaybookVisibility).describe("determines the visibility of the playbook on Tekkr"),
    targets: zod_1.z.array(zod_1.z.strictObject({
        id: zod_1.z.string().regex(schema_refinements_1.formats.kebabCaseRegex),
        title: zod_1.z.string(),
        description: zod_1.z.string(),
    })),
    peopleGroups: zod_1.z.array(people_groups_1.peopleGroupBlueprintSchema).superRefine((0, schema_refinements_1.validateUniqueIds)((group) => group.id)).optional(),
    segments: zod_1.z.array(blueprint_1.segmentBlueprintSchema).superRefine((0, schema_refinements_1.validateUniqueIds)((segment) => segment.id)),
    targetReview: target_review_1.playbookTargetsReviewBlueprintSchema,
    alignment: alignment_1.playbookAlignmentBlueprintSchema,
    communication: communication_1.playbookCommunicationBlueprintSchema,
    relatedBlueprints: zod_1.z.array(zod_1.z.string()).optional(),
    todos: zod_1.z.array(blueprint_2.playbookTodoBlueprintSchema).optional(),
    dummyMetrics: zod_1.z.array(zod_1.z.strictObject({
        name: zod_1.z.string(),
        unit: zod_1.z.string().optional(),
        description: zod_1.z.string(),
        values: zod_1.z.array(zod_1.z.number()),
        goal: zod_1.z.enum(["up", "down"]),
        success: zod_1.z.enum(["good", "bad", "neutral"]),
    })).min(1).optional(),
}).strict().superRefine((pb, ctx) => {
    // check that all people groups exist
    const availablePeopleGroups = new Set(pb.peopleGroups?.map(g => g.id) ?? []);
    (0, iterate_object_paths_1.iterateObjectPaths)(pb, peopleGroupPaths, (value, path) => {
        if (!availablePeopleGroups.has(value)) {
            ctx.addIssue({
                path,
                code: zod_1.z.ZodIssueCode.custom,
                message: `people group '${value} is not defined in this blueprint'`,
            });
        }
    });
    // check that segment ids in todos exist - todo: also check contentId
    const allSegmentIds = new Set(pb.segments.map(s => s.id));
    (0, iterate_object_paths_1.iterateObjectPaths)(pb, ["todos.*.segmentId"], (value, path) => {
        if (!allSegmentIds.has(value)) {
            ctx.addIssue({
                path,
                code: zod_1.z.ZodIssueCode.custom,
                message: `segment with id '${value}' does not exist.`
            });
        }
    });
});
