"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiContract = void 0;
const core_1 = require("@ts-rest/core");
const zod_1 = require("zod");
const base_1 = require("../playbook/base");
const playbook_1 = require("../playbook/playbook");
const state_1 = require("../playbook/state");
const targets_1 = require("../playbook/targets");
const implementation_1 = require("../playbook/stages/implementation");
const people_groups_1 = require("../playbook/people-groups");
const target_review_1 = require("../playbook/stages/target-review");
const alignment_1 = require("../playbook/stages/alignment");
const communication_1 = require("../playbook/stages/communication");
const edit_1 = require("../playbook/segment/content/types/tool-template/edit");
const blueprint_1 = require("../playbook/segment/content/types/tool-template/blueprint");
const c = (0, core_1.initContract)();
// todo consolidate all update playbook endpoints into a patch endpoint
exports.apiContract = c.router({
    getAccount: {
        method: "GET",
        path: "/account",
        responses: {
            200: zod_1.z.strictObject({
                uid: zod_1.z.string(),
                access: zod_1.z.strictObject({
                    createOrg: zod_1.z.boolean(),
                }),
                orgs: zod_1.z.array(zod_1.z.strictObject({
                    id: zod_1.z.string(),
                    userId: zod_1.z.string(),
                    name: zod_1.z.string()
                })),
            }),
        },
        summary: "Get the current user's account",
    },
    listBlueprints: {
        method: "GET",
        path: "/blueprint",
        responses: {
            200: zod_1.z.strictObject({
                data: zod_1.z.array(zod_1.z.intersection(base_1.basePlaybookSchema, zod_1.z.strictObject({
                    access: zod_1.z.enum(["restricted", "access"]),
                })))
            })
        },
        query: zod_1.z.strictObject({
            term: zod_1.z.string().optional(),
        }).optional(),
        summary: "List all available blueprints",
    },
    getBlueprintMetaById: {
        method: "GET",
        path: "/blueprint/:id/meta",
        responses: {
            200: zod_1.z.intersection(base_1.basePlaybookSchema, zod_1.z.strictObject({
                access: zod_1.z.enum(["restricted", "access"]),
            }))
        },
        summary: "Get metadata of a specific blueprint (does not require access to blueprint)"
    },
    getBlueprintById: {
        method: "GET",
        path: "/blueprint/:id",
        responses: {
            200: zod_1.z.any(),
        },
        summary: "Get a playbook blueprint by id",
    },
    getPlaybookById: {
        method: "GET",
        path: "/playbook/:playbookId",
        responses: {
            200: zod_1.z.object({
                data: zod_1.z.intersection(playbook_1.playbookSchema, zod_1.z.strictObject({
                    imageUrl: zod_1.z.string(),
                }))
            }),
        },
        summary: "Get a playbook by id",
    },
    updatePlaybookEdit: {
        method: "PUT",
        path: "/playbook/:playbookId/edit",
        responses: {
            200: zod_1.z.strictObject({
                ok: zod_1.z.boolean(),
            })
        },
        body: zod_1.z.any(),
        summary: "Update the edit of a playbook",
    },
    updatePlaybookPeopleGroups: {
        method: "PUT",
        path: "/playbook/:playbookId/people-groups",
        responses: {
            200: zod_1.z.strictObject({
                ok: zod_1.z.boolean(),
            })
        },
        body: people_groups_1.playbookPeopleGroupsSchema,
        summary: "Update the people groups of a playbook",
    },
    updatePlaybookTargets: {
        method: "PUT",
        path: "/playbook/:playbookId/targets",
        responses: {
            200: zod_1.z.strictObject({
                ok: zod_1.z.boolean(),
            })
        },
        body: targets_1.playbookTargetsSchema,
        summary: "Update the targets of a playbook",
    },
    updatePlaybookTargetsReview: {
        method: "PUT",
        path: "/playbook/:playbookId/targets-review",
        responses: {
            200: zod_1.z.strictObject({
                ok: zod_1.z.boolean(),
            })
        },
        body: target_review_1.playbookTargetsReviewEditSchema,
        summary: "Update the targets review stage of a playbook",
    },
    updatePlaybookAlignment: {
        method: "PUT",
        path: "/playbook/:playbookId/alignment",
        responses: {
            200: zod_1.z.strictObject({
                ok: zod_1.z.boolean(),
            })
        },
        body: alignment_1.playbookAlignmentEditSchema,
        summary: "Update the alignment stage of a playbook",
    },
    updatePlaybookImplementation: {
        method: "PUT",
        path: "/playbook/:playbookId/implementation",
        responses: {
            200: zod_1.z.strictObject({
                ok: zod_1.z.boolean(),
            })
        },
        body: implementation_1.playbookImplementationSchema,
        summary: "Update the implementation stage of a playbook",
    },
    updatePlaybookCommunication: {
        method: "PUT",
        path: "/playbook/:playbookId/communication",
        responses: {
            200: zod_1.z.strictObject({
                ok: zod_1.z.boolean(),
            })
        },
        body: communication_1.playbookCommunicationSchema,
        summary: "Update the communication stage of a playbook",
    },
    updatePlaybookContributors: {
        method: "PATCH",
        path: "/playbook/:playbookId/contributors",
        responses: {
            200: zod_1.z.strictObject({
                ok: zod_1.z.boolean(),
            }),
        },
        body: zod_1.z.strictObject({
            owner: zod_1.z.string().optional(),
            driver: zod_1.z.string().nullable().optional(),
        }),
        summary: "Update Contributors of a playbook (i.e. Driver, Owner)"
    },
    createPlaybook: {
        method: "POST",
        path: "/playbook/",
        body: zod_1.z.strictObject({
            blueprintId: zod_1.z.string(),
            title: zod_1.z.string().min(2),
        }),
        responses: {
            201: zod_1.z.strictObject({
                id: zod_1.z.string(),
            }),
        },
    },
    deletePlaybook: {
        method: "DELETE",
        path: "/playbook/:playbookId",
        body: zod_1.z.any().optional(),
        responses: {
            200: zod_1.z.strictObject({
                ok: zod_1.z.boolean(),
            }),
        },
    },
    sharePlaybook: {
        method: "POST",
        path: "/playbook/:playbookId/share",
        body: zod_1.z.any().optional(),
        responses: {
            201: zod_1.z.strictObject({
                key: zod_1.z.string(),
            }),
        },
    },
    useToolTemplate: {
        method: "PUT",
        path: "/playbook/:playbookId/use-tool-template",
        body: zod_1.z.strictObject({
            service: zod_1.z.enum(["google"]),
            blueprint: blueprint_1.toolTemplateContentBlueprintSchema, // todo not the neatest solution, this should be a reference somehow
        }),
        responses: {
            200: edit_1.toolTemplateContentEditSchema,
        },
    },
    exportImplementationTasks: {
        method: "PUT",
        path: "/playbook/:playbookId/export-implementation-tasks",
        body: zod_1.z.strictObject({
            service: zod_1.z.enum(["asana"]),
        }),
        responses: {
            200: zod_1.z.strictObject({
                exportResultUrl: zod_1.z.string(),
            }),
        },
    },
    replacePlaybookImage: {
        method: "POST",
        path: "/playbook/:playbookId/image",
        contentType: "multipart/form-data",
        body: zod_1.z.object({
            image: zod_1.z.unknown(),
        }),
        responses: {
            200: zod_1.z.object({
                ok: zod_1.z.boolean(),
            }),
        },
    },
    createOrg: {
        method: "POST",
        path: "/org/",
        responses: {
            201: zod_1.z.strictObject({
                id: zod_1.z.string(),
                name: zod_1.z.string(),
            })
        },
        body: zod_1.z.strictObject({
            name: zod_1.z.string().min(2),
        }),
    },
    createOrgUser: {
        method: "POST",
        path: "/org/user",
        responses: {
            201: zod_1.z.strictObject({
                name: zod_1.z.string(),
                id: zod_1.z.string(),
            }),
        },
        body: zod_1.z.strictObject({
            name: zod_1.z.string().min(2).max(50),
            title: zod_1.z.string().max(50).optional(),
            email: zod_1.z.string().email(),
        }),
        query: zod_1.z.strictObject({
            emailInvite: zod_1.z.enum(["true", "false"]).transform((value) => value === "true").optional(),
        }),
        summary: "Create a new user in a specific organization",
    },
    installSlackToOrg: {
        method: "POST",
        path: "/org/slack/install",
        body: zod_1.z.strictObject({
            code: zod_1.z.string(),
            redirect: zod_1.z.string(),
        }),
        responses: {
            200: zod_1.z.strictObject({
                ok: zod_1.z.boolean(),
            }),
        },
    },
    importSlackUsers: {
        method: "POST",
        path: "/org/slack/import-users",
        body: zod_1.z.any().optional(),
        responses: {
            200: zod_1.z.strictObject({
                ok: zod_1.z.boolean(),
            }),
        },
    },
    getOrg: {
        method: "GET",
        path: "/org",
        responses: {
            200: zod_1.z.strictObject({
                id: zod_1.z.string(),
                name: zod_1.z.string(),
                logo: zod_1.z.object({
                    urlBlack: zod_1.z.string(),
                    urlWhite: zod_1.z.string(),
                }).optional(),
                users: zod_1.z.array(zod_1.z.strictObject({
                    name: zod_1.z.string(),
                    title: zod_1.z.string().optional(),
                    email: zod_1.z.string(),
                    id: zod_1.z.string(),
                    hasAccount: zod_1.z.boolean(),
                    linkedAccounts: zod_1.z.array(zod_1.z.string()),
                    imageUrl: zod_1.z.string().optional(),
                })),
                playbookAccess: zod_1.z.union([
                    zod_1.z.strictObject({ none: zod_1.z.literal(true) }),
                    zod_1.z.strictObject({ limited: zod_1.z.array(zod_1.z.string()) }),
                    zod_1.z.strictObject({ unrestricted: zod_1.z.literal(true) }),
                ])
            }),
        },
        summary: "Get current organization",
    },
    createUserInvite: {
        method: "PUT",
        path: "/org/user/:userId/invite",
        responses: {
            201: zod_1.z.strictObject({
                token: zod_1.z.string(),
                validUntil: zod_1.z.date(),
            })
        },
        query: zod_1.z.strictObject({
            sendEmail: zod_1.z.enum(["true", "false"]).transform((value) => value === "true"),
        }),
        body: zod_1.z.any(),
        summary: "Create a invite for a user to join the org with their account",
    },
    acceptInvite: {
        method: "GET",
        path: "/invite/:token/accept",
        responses: {
            200: zod_1.z.strictObject({
                ok: zod_1.z.boolean(),
            }),
        },
        summary: "Accept an invite to join an organization with the users account",
    },
    getInvite: {
        method: "GET",
        path: "/invite/:token",
        responses: {
            200: zod_1.z.strictObject({
                orgName: zod_1.z.string(),
                validUntil: zod_1.z.string(),
            }),
        },
        summary: "Get context about an invite",
    },
    listPlaybooks: {
        method: "GET",
        path: "/playbook",
        query: zod_1.z.strictObject({
            blueprintId: zod_1.z.string().optional(),
        }),
        responses: {
            200: zod_1.z.strictObject({
                data: zod_1.z.array(zod_1.z.intersection(base_1.basePlaybookSchema, zod_1.z.strictObject({
                    state: state_1.playbookStateSchema,
                    createdAt: zod_1.z.string(),
                    contributors: zod_1.z.object({
                        owner: zod_1.z.string(),
                        driver: zod_1.z.string().optional(),
                    }),
                })))
            }),
        },
        summary: "Fetch the playbooks in a account",
    },
    generateCopy: {
        method: "PUT",
        path: "/generate/:type",
        body: zod_1.z.strictObject({
            playbookId: zod_1.z.string(),
            dynamicVars: zod_1.z.record(zod_1.z.string(), zod_1.z.string()).optional(),
        }),
        query: zod_1.z.strictObject({
            format: zod_1.z.enum(["html", "gcal"]).optional(),
        }),
        responses: {
            200: zod_1.z.strictObject({
                message: zod_1.z.string(),
            }),
        },
    },
    getProfilePicture: {
        method: "GET",
        path: "/org/user/:userId/picture",
        responses: {
            200: zod_1.z.string().describe("base64 string representing the image"),
            204: zod_1.z.string(),
        },
    },
    linkOauthAccount: {
        method: "GET",
        path: "/account/profile/:service/authorize",
        responses: {
            200: zod_1.z.strictObject({
                authUrl: zod_1.z.string(),
            }),
        },
    },
}, {
    pathPrefix: "/api",
});
