import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/ui/table";
import React, { ReactElement } from "react";
import { PlaybookStage } from "tekkr-common/dist/model/playbook/enums/enums";
import { playbookStageTitles } from "tekkr-common/dist/model/playbook/static/copy";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../service/tekkr-service";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { Link } from "react-router-dom";
import { Check, Minus, TriangleAlert } from "lucide-react";
import { cn } from "../../../lib/utils";
import { useOrgUser, useOrgUserMap } from "../../../auth/org-provider";
import { AvatarForUser } from "../../../components/shared/avatar-for-user";

type PlaybookList = ClientInferResponseBody<typeof apiContract.listPlaybooks>["data"];

const RelevantStages = Object.values(PlaybookStage).filter(s => s !== PlaybookStage.tracking);

const states: Record<string, { title: string, indicator: ReactElement }> = {
    done: {
        title: "Done",
        indicator: <Check strokeWidth={5} className={"w-4 h-4 text-confirmation"}></Check>,
    },
    inProgress: {
        title: "In Progress",
        indicator: <div className={"w-3 h-3 rounded-full bg-blue-700"}></div>,
    },
    delayed: {
        title: "Delayed",
        indicator: <TriangleAlert className={"w-5 h-5 fill-red-700 text-background"} />
    },
    pending: {
        title: "To Do",
        indicator: <div className={"w-3 h-3 rounded-full bg-secondary"}></div>,
    },
    notApplicable: {
        title: "Not Applicable",
        indicator: <Minus className={"text-secondary"} strokeWidth={3} />,
    }
}

function getState(state: number | undefined): keyof typeof states {
    if (state === undefined) {
        return "notApplicable";
    } else if (state === 1) {
        return "done";
    } else if (state > 0) {
        return "inProgress";
    } else if (state === 0) {
        return "pending";
    } else {
        throw new Error("unknown state");
    }
}

function PlaybookRow(props: { playbook: PlaybookList[0] }) {
    const driverId = props.playbook.contributors.driver ?? props.playbook.contributors.owner;
    const driver = useOrgUser(driverId);
    return <TableRow key={props.playbook.id}>
        <TableCell className="font-medium flex flex-col gap-1.5">
            <Link className={"hover:underline"} to={`/playbook/${props.playbook.id}`}>{ props.playbook.title }</Link>
            <div className={"flex flex-row items-center gap-2 text-sm"}>
                <span className={"text-muted-foreground"}>Driver:</span>
                <AvatarForUser user={driver} className={"w-5 h-5"} />
                <span>{ driver.name }</span>
            </div>
        </TableCell>
        { RelevantStages.map(stage => {
            const state = getState(props.playbook.state[stage]);
            return <TableCell key={stage}>
                <div className={"flex flex-col items-center"}>
                    { states[state].indicator }
                </div>
            </TableCell>
        })}
    </TableRow>
}

export function PlaybookOverviewAnalyticsTable(props: React.HTMLProps<HTMLElement>) {
    const { data } = useQuery({
        queryKey: ["playbooks"],
        queryFn: async () => {
            const res = await apiClient.listPlaybooks({});
            const body = res.body as ClientInferResponseBody<typeof apiContract.listPlaybooks>;
            return body.data;
        }
    });
    // todo empty state
    return <div className={cn("border border-secondary rounded-md overflow-hidden", props.className)}>
        <Table>
            <TableHeader>
                <TableRow className={"bg-secondary"}>
                    <TableHead>Playbook</TableHead>
                    { RelevantStages.map(stage => <TableHead className={"text-center w-[10%] whitespace-nowrap"} key={stage}>{ playbookStageTitles.past[stage] }</TableHead>) }
                </TableRow>
            </TableHeader>
            <TableBody className={"[&_tr:last-child]:border-b"}>
                { data?.map(playbook => <PlaybookRow key={playbook.id} playbook={playbook} />) }
            </TableBody>
            <TableCaption className={"p-4 mt-0"}>
                <div className={"flex flex-row justify-center gap-4"}>
                    { Object.values(states).map(state => <div
                        className={"flex flex-row items-center gap-1"}
                        key={state.title}>
                        {state.indicator}
                        <span>=</span>
                        <span>{ state.title }</span>
                    </div>) }
                </div>
            </TableCaption>
        </Table>
    </div>
}