import React from "react";
import { Tooltip, TooltipContent, TooltipProvider } from "../ui/tooltip";
import { DateTime } from "luxon";

interface Props {
    data: {
        title: string,
        summary: string,
        imageUrl: string,
        createdOn: Date,
    }
}

export function PlaybookTooltip(props: React.PropsWithChildren<Props>) {
    const createdOnString = DateTime.fromJSDate(props.data.createdOn).toLocaleString({
        year: "numeric",
        month: "long",
        day: "numeric",
    })
    return <TooltipProvider>
        <Tooltip disableHoverableContent={true}>
            { props.children }
            <TooltipContent className={"flex flex-col gap-0 w-80 p-4 drop-shadow-md shadow-black/50"}>
                <img src={props.data.imageUrl} alt={"Playbook Cover"} className={"bg-accent rounded-md h-52 border-0 object-cover"} />
                <div className={"text-base font-semibold mt-2"}>{ props.data.title }</div>
                <div className={"text-sm text-muted-foreground font-medium"}>Created on { createdOnString }</div>
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
}