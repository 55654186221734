import { usePlaybook, usePlaybookOptional } from "../../../../pages/playbook/controller/hooks/playbook";
import { MarkdownContentEdit } from "tekkr-common/dist/model/playbook/segment/content/types/markdown/blueprint";
import { useComputedReducer } from "../../../../lib/utils";
import { useEditCallback } from "../../../hooks/use-edit";
import React from "react";
import { useBlueprint } from "../../../../pages/playbook/controller/hooks/blueprint";
import { InlineEditable } from "tekkr-common/dist/lib/blueprint-wrapper";
import { Playbook } from "tekkr-common/dist/model/playbook/playbook";
import { ContentType } from "tekkr-common/dist/model/playbook/segment/content/blueprint";
import { ChecklistContentEdit } from "tekkr-common/dist/model/playbook/segment/content/types/checklist/edit";
import { formats } from "tekkr-common/dist/utils/formatting";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../../ui/tooltip";
import { SegmentBlueprint } from "tekkr-common/dist/model/playbook/segment/blueprint";

export function ReferenceMarkdownComponent(props: { type: string, id: string }) {
    if (props.type === "editable") {
        return <EditableReference id={props.id} />
    } else if (props.type === "value") {
        return <ValueReference id={props.id} />
    } else {
        return <>#invalid-reference#</>
    }
}

function NoValueSelectedPlaceholder(props: { segment: SegmentBlueprint; placeholder?: string }) {
    return <TooltipProvider>
        <Tooltip>
            <TooltipTrigger>
                <span className={"text-sm bg-accent p-1 rounded-md text-muted-foreground font-medium cursor-help"}>{props.placeholder ?? "?"}</span>
            </TooltipTrigger>
            <TooltipContent className={"max-w-md text-center"}>
                You haven't selected "{props.placeholder}". Edit the section {props.segment.title} of this playbook to do so.
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
}

function ValueReference(props: { id: string }) {
    const pb = usePlaybookOptional();
    const bp = useBlueprint();

    const path = props.id.split(".");
    if (path.length !== 2) {
        throw new Error("invalid path input")
    }

    const [segmentId, contentId] = path;

    const segmentBlueprint = bp.blueprint.segments.find(s => s.id === segmentId);
    if (!segmentBlueprint) {
        throw new Error(`couldn't find '${props.id}'`);
    }

    if (!pb) {
        return <NoValueSelectedPlaceholder segment={segmentBlueprint} />;
    } else {
        const { edit: segmentEdit } = pb.pw.getSegmentAndEdit(segmentId);
        if (!segmentEdit?.content) {
            return <NoValueSelectedPlaceholder segment={segmentBlueprint} />;
        }
        const content = segmentBlueprint.content.find((c) => "id" in c && c.id === contentId);
        if (!content) {
            throw new Error(`couldn't find '${props.id}'`);
        }
        if (content.type === ContentType.list) {
            const listEdit = (segmentEdit?.content?.[content.id] as ChecklistContentEdit | undefined);
            const allItems: string[] = [
                ...(listEdit?.customItems?.map(item => item.title) ?? []),
                ...(listEdit?.selectedItems?.map(selected => content.items.find(it => it.id === selected))?.filter(item => !!item)?.map(item => item!.title) ?? [])
            ]

            if (!allItems.length) {
                return <NoValueSelectedPlaceholder segment={segmentBlueprint} placeholder={content.title} />;
            }

            return <>{ formats.friendly.commaList(allItems) }</>
        } else {
            throw new Error(`content type ${content.type} is not supported`);
        }
    }
}

function EditableReference(props: { id: string }) {
    const { bw } = useBlueprint();
    const editable = bw.getInlineEditables()[props.id];

    const playbook = usePlaybookOptional();

    if (playbook) {
        return <EditedEditableReference id={props.id} editable={editable} playbook={playbook.playbook} />
    } else {
        return <span>{ editable.defaultValue }</span>
    }
}

function EditedEditableReference(props: { id: string, editable: InlineEditable, playbook: Playbook }) {
    const { editable, playbook } = props;
    const segmentEdit = playbook ? playbook.edit?.segments?.[editable.location.segmentId] : undefined
    const mdEdit = segmentEdit?.content?.[editable.location.contentId] as MarkdownContentEdit | undefined;

    const [v, dispatch] = useComputedReducer(() => {
        return mdEdit?.editableText?.[props.id] || editable.defaultValue;
    });
    useEditCallback(dispatch);

    return <span>{v}</span>;
}