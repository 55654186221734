import {
    Calendar,
    CalendarClock,
    ChartLine,
    Check,
    Flame,
    ListTodo,
    Megaphone,
    MessagesSquare,
    Pen,
    Target,
} from "lucide-react";
import React from "react";
import { PlaybookStage } from "tekkr-common/dist/model/playbook/enums/enums";
import { cn } from "../../lib/utils";
import { IconForPlaybookStage } from "../../components/shared/icon-playbook-stage";
import { playbookStageTitles } from "tekkr-common/dist/model/playbook/static/copy";
import { Button } from "../../components/ui/button";
import AnimateHeight from "react-animate-height";

type SidebarButtonVariant =  "active" | "upcoming" | "done";

type StepIcon = "target" | "communicate" | "schedule" | "edit" | "roasting" | "todos" | "messages" | "graphs" | "wait";

type NavState = "done" | "todo";

interface SubNavItem {
    step: string;
    icon: StepIcon;
    name: string;
    id?: string;
    state: NavState;
    blocked?: boolean;
}

export interface PlaybookSidebarNavigation {
    stages: {
        stage: PlaybookStage;
        state: NavState;
        steps?: SubNavItem[];
    }[];
}

type NavPosition = {
    stage: PlaybookStage,
    step?: string,
    stepId?: string,
};

type PlaybookSidebarProps = {
    nav: PlaybookSidebarNavigation,
    selected: NavPosition,
    recommendedPosition?: NavPosition,
    onSelect: (selected: { stage: PlaybookStage, step?: string, stepId?: string }) => void,
};

function isSamePosition (pos1: NavPosition, pos2: NavPosition): boolean {
    return pos1.stage === pos2.stage
        && pos1.step === pos2.step
        && pos1.stepId === pos2.stepId;
}

function StepIconView (props: React.HTMLProps<SVGSVGElement> & { name: StepIcon }) {
    const { name } = props;
    if (name === "target") {
        return <Target {...props} />
    } else if (name === "schedule") {
        return <Calendar {...props} />
    } else if (name === "communicate") {
        return <Megaphone {...props} />
    } else if (name === "edit") {
        return <Pen {...props} />
    } else if (name === "roasting") {
        return <Flame {...props} />
    } else if (name === "todos") {
        return <ListTodo {...props} />
    } else if (name === "messages") {
        return <MessagesSquare {...props} />
    } else if (name === "graphs") {
        return <ChartLine {...props} />
    } else if (name === "wait") {
        return <CalendarClock {...props} />
    }
    throw new Error(`unsupported step icon ${name}`);
}

function isStageDone(stage: { stage: PlaybookStage, state: NavState }) {
    return stage.state === "done";
}

const sidebarButtonStyles: Record<SidebarButtonVariant, string> = {
    active: "hover:cursor-pointer hover:bg-accent font-semibold",
    upcoming: "opacity-65 cursor-not-allowed",
    done: "text-confirmation hover:cursor-pointer hover:bg-accent"
}
type SidebarStageButtonProps = {
    stage: PlaybookStage,
    variant: SidebarButtonVariant,
    expanded: boolean;
    selected: boolean,
    onClick: () => void,
}
function SidebarStageButton(props: SidebarStageButtonProps) {
    const onClick = props.variant === "upcoming" ? undefined : props.onClick;
    return <div onClick={onClick}
                className={cn("flex flex-row items-center gap-2 transition-all rounded-md px-4 py-2", sidebarButtonStyles[props.variant], props.selected ? "bg-accent" : undefined)}>
        <IconForPlaybookStage className={"w-5 h-5"} stage={props.stage} />
        { playbookStageTitles.upcoming[props.stage] }
    </div>;
}

interface SidebarStepButtonProps {
    onSelect: PlaybookSidebarProps["onSelect"];
    stage: PlaybookStage;
    item: SubNavItem;
    selected: boolean;
    recommended?: boolean;
    enabled: boolean;
}
function SidebarStepButton (props: SidebarStepButtonProps) {
    const isDone = props.item.state === "done";
    const Icon = isDone ? Check : StepIconView;

    const onClick = props.enabled ?
        () => props.onSelect({ stage: props.stage, step: props.item.step, stepId: props.item.id })
        : undefined;

    return <div
        className={cn("transition-all w-full ms-1 py-1 px-2 text-sm text-muted-foreground flex flex-row gap-2 items-start rounded-md", props.recommended && "text-foreground font-semibold", props.enabled ? "cursor-pointer hover:bg-accent" : "cursor-not-allowed", props.selected && "bg-accent text-foreground")}
        onClick={onClick}>
        <Icon className={"w-4 h-4 shrink-0 mt-0.5"} name={props.item.icon} />
        {props.item.name}
    </div>;
}

function ResumePlaybookProcessShortcut (props: {
    onResume: () => void;
    recommendedPosition: NavPosition;
    nav: PlaybookSidebarNavigation;
    hide?: boolean;
}) {
    const stage = props.recommendedPosition.stage;
    const step = props.nav.stages.find(s => s.stage === stage)!.steps?.find(s => s.step === props.recommendedPosition.step && s.id === props.recommendedPosition.stepId);
    return <AnimateHeight height={props.hide ? 0 : "auto"}>
        <div className={"flex flex-col w-full pt-4"}>
            <div
                className={"text-sm text-muted-foreground mb-2 text-center animate-in slide-in-from-top-1 fade-in duration-500"}>
                Pick up where you left:
            </div>
            <div
                className={"rounded-md bg-accent px-3 py-2 flex flex-col items-center  animate-in slide-in-from-top-3 fade-in duration-500"}>
                <div className={"font-semibold text-sm flex flex-row items-center mb-1"}>
                    <IconForPlaybookStage stage={stage} className={"w-4 h-4 me-1"} />
                    {playbookStageTitles.upcoming[stage]}
                </div>
                <div className={"text-sm"}>{step?.name}</div>
                <Button onClick={props.onResume} variant={"secondary"} size={"sm2"}
                        className={"mt-2 w-full"}>Resume</Button>
            </div>
        </div>
    </AnimateHeight>
}

export function PlaybookSidebar(props: PlaybookSidebarProps) {
    return <div
        className={"w-64 shrink-0 p-8 py-0 border-r-2 border-r-accent animate-in slide-in-from-left fade-in duration-500 select-none"}>
        <div className={"py-9"}>
            {props.nav.stages.map((stage, index) => {
                const prev = index === 0 ? undefined : props.nav.stages[index - 1];
                const done = isStageDone(stage);
                const prevDone = prev && isStageDone(prev);
                let variant: SidebarButtonVariant = "upcoming";
                if (done) {
                    variant = "done";
                } else if (!prev || prevDone) {
                    variant = "active";
                }
                const stageSelected = props.selected.stage === stage.stage;
                const isLast = index === props.nav.stages.length - 1;
                return <div key={stage.stage}>
                    <SidebarStageButton
                        key={stage.stage}
                        stage={stage.stage}
                        variant={variant}
                        expanded={stageSelected}
                        selected={stageSelected && !props.selected.step}
                        onClick={() => props.onSelect({ stage: stage.stage })} />
                    { stage.steps &&
                        <div className={"flex flex-row"}>
                            <div className={cn("w-0.5 shrink-0 rounded-md ms-6 my-1", done ? "bg-confirmation" : "bg-border", !isLast && "min-h-4", isLast && !stageSelected && "invisible")}></div>
                            <AnimateHeight key={`subnav-${stage.stage}`} className={"mt-2 mb-1 w-full"} height={stageSelected ? "auto" : 0}>
                                    <div className={"min-h-6 pb-1 flex flex-col gap-1 w-full"}>
                                        {stage.steps.map((i, index) => {
                                            const prev = index === 0 ? undefined : stage.steps?.[index - 1];
                                            const stepSelected = props.selected.step === i.step && (!i.id || props.selected.stepId === i.id)
                                            return <SidebarStepButton
                                                enabled={(!prev || prev.state !== "todo") && !i.blocked}
                                                recommended={props.recommendedPosition && isSamePosition(props.recommendedPosition, { stage: stage.stage, ...i })}
                                                key={i.step + "-" + i.id}
                                                onSelect={() => props.onSelect({
                                                    stage: stage.stage,
                                                    step: i.step,
                                                    stepId: i.id
                                                })}
                                                stage={stage.stage}
                                                item={i}
                                                selected={stepSelected} />
                                        })}
                                    </div>
                            </AnimateHeight>
                        </div>}
                </div>;
            })}

            {props.recommendedPosition &&
                <ResumePlaybookProcessShortcut
                    nav={props.nav}
                    hide={props.selected.stage !== PlaybookStage.edit}
                    recommendedPosition={props.recommendedPosition}
                    onResume={() => props.onSelect(props.recommendedPosition!)}
                />
            }
        </div>
    </div>
}