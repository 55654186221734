import { ContentSpacer } from "../common/layout";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { apiClient, ApiError } from "../../service/tekkr-service";
import { PlaybookBlueprint } from "tekkr-common/dist/model/playbook/blueprint";
import LoadingCard from "../../components/shared/loading-card";
import PlaybookView from "../../components/shared/playbook-view/playbook-view";
import { Button } from "../../components/ui/button";
import { Edit } from "lucide-react";
import React, { useEffect } from "react";
import BackButtonRow from "../../components/shared/back-button-row";
import { PlaybookHeader } from "../../components/shared/playbook-view/playbook-header";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { PlaybookPageNavigationState } from "../playbook/playbook-page";
import { cn } from "../../lib/utils";
import Spinner from "../../components/ui/spinner";
import { BlueprintPageLibraryBox } from "./components/library-box";
import { BlueprintProvider } from "../playbook/controller/hooks/blueprint";
import { ErrorCode } from "tekkr-common/dist/model/api/error-codes";
import { NoAccessErrorPageContent } from "../../components/shared/error-message";

function useCreatePlaybookMutation () {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (blueprint: PlaybookBlueprint) => {
      const response = await apiClient.createPlaybook({
        body: {
          blueprintId: blueprint.id,
          title: blueprint.title,
        },
      });
      const playbook = response.body as ClientInferResponseBody<
          typeof apiContract.createPlaybook,
          201
      >;
      const navState: PlaybookPageNavigationState = {
        openEditMode: true,
      };
      navigate(`/playbook/${playbook.id}?view=edit&new_playbook=true`, { state: navState });
    },
  });
}

export default function BlueprintPage() {
  const { blueprintId } = useParams();
  if (!blueprintId) {
    throw new Error("blueprint id missing");
  }

  const blueprintQuery = useQuery({
    queryKey: ["blueprint", blueprintId],
    queryFn: async () => {
      const response = await apiClient.getBlueprintById({
        params: { id: blueprintId },
      });
      return response.body as PlaybookBlueprint;
    },
  });

  const libraryQuery = useQuery({
    queryKey: ["playbooks", "by-blueprint", blueprintQuery.data?.id],
    queryFn: async () => {
      const response = await apiClient.listPlaybooks({
        query: { blueprintId: blueprintQuery.data!.id },
      });
      return (
        response.body as ClientInferResponseBody<
          typeof apiContract.listPlaybooks,
          200
        >
      ).data;
    },
    enabled: !!blueprintQuery.data,
  });

  useEffect(() => {
    document.title = `${blueprintQuery.data?.title ?? "Playbook"} - Tekkr`;
  }, [blueprintQuery.data?.title]);

  const createPlaybookMutation = useCreatePlaybookMutation();

  if (blueprintQuery.isError && ApiError.isApiErrorWithCode(blueprintQuery.error, ErrorCode.org_no_blueprint_access)) {
    return <ContentSpacer>
      <NoAccessErrorPageContent />
    </ContentSpacer>
  }

  return (
    <>
      {blueprintQuery.isPending ? (
        <ContentSpacer>
          <LoadingCard />
        </ContentSpacer>
      ) : (
        <div className={"px-8 py-6"}>
          <BackButtonRow></BackButtonRow>
          <ContentSpacer contentWidth={"narrower"}>
            <PlaybookHeader mode={"blueprint"} blueprint={blueprintQuery.data!}>
              {!!libraryQuery.data && libraryQuery.data.length > 0 ? (
                <BlueprintPageLibraryBox library={libraryQuery.data} />
              ) : null}
            </PlaybookHeader>
            <BlueprintProvider blueprint={blueprintQuery.data!}>
              <PlaybookView isEditing={false} />
            </BlueprintProvider>
          </ContentSpacer>
        </div>
      )}
      {blueprintQuery.data ? (
        <>
          <div className={"h-32"}></div>
          <div
            className={
              "fixed p-4 w-full bg-primary bottom-0 z-40 text-primary-foreground flex flex-row items-center justify-center gap-12 animate-in slide-in-from-bottom-12 fade-in duration-300"
            }
          >
            <div className={"flex flex-col max-w-lg gap-0.5"}>
              <h3>
                Edit this playbook to make it your own!
              </h3>
              <p>
                Tekkr editing makes it easy to tailor this playbook to your
                team.
              </p>
            </div>
            <Button
                variant={"outline-primary-foreground"}
                disabled={createPlaybookMutation.isPending}
                onClick={() => createPlaybookMutation.mutate(blueprintQuery.data!)}>
              <div className={cn("contents", createPlaybookMutation.isPending && "invisible")}>
                <Edit className={"w-5 h-5"} />
                <span className={"font-semibold ms-2"}>Start editing</span>
              </div>
              { createPlaybookMutation.isPending && <Spinner className={"absolute"} /> }
            </Button>
          </div>
        </>
      ) : null}
    </>
  );
}
