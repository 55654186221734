import React from "react";
import { StagePageContainer } from "../stage-container";
import { Button } from "../../../../components/ui/button";
import { MessageSquare } from "lucide-react";
import { IconForPlaybookStage } from "../../../../components/shared/icon-playbook-stage";
import { PlaybookStage } from "tekkr-common/dist/model/playbook/enums/enums";
import { usePlaybookPageNavigation } from "../../playbook-page";
import { usePlaybookAlignmentEdit } from "../../controller/hooks/alignment-edit";

function MessagesAnimation () {
    return <div className={"flex flex-row items-center gap-0.5"}>
        <MessageSquare className={"w-8 h-8 animate-conversation-bubble-left"} />
        <MessageSquare className={"w-8 h-8 animate-conversation-bubble-right"} />
    </div>
}

function DialogContent (props: {
    onProceedClicked: () => void,
}) {
    return <div className={"flex flex-col items-center gap-4"}>
        <MessagesAnimation />
        <h2>Run your Alignment</h2>
        <p className={"text-center"}>It's time to run your alignment meetings or wait for feedback on the requests you've sent out. Come back here once you have aligned with everyone.</p>
        <Button onClick={props.onProceedClicked} className={"group"}>
            <IconForPlaybookStage stage={PlaybookStage.implementation} className={"transition-all duration-500 w-5 h-5 me-2 group-hover:rotate-12"} />
            Start Implementation
        </Button>
    </div>;
}

export function PlaybookReviewHoldPage () {
    const { alignmentEdit, updateAlignment } = usePlaybookAlignmentEdit();

    const nav = usePlaybookPageNavigation();
    const moveOn = () => {
        alignmentEdit.reviewsCompleted = true;
        updateAlignment();
        nav?.goToNext();
    }
    return <StagePageContainer
        hideNavigationButtons={true}
        header={"Alignment"}
        overlayDialog={<DialogContent onProceedClicked={moveOn} />}
        explainer={`Review your playbook to surface and address concerns early.`}>
    </StagePageContainer>
}