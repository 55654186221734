import { Progress } from "../../../ui/progress";
import React, { useState } from "react";
import { useCurrentOrgUserOptional, useOrg } from "../../../../auth/org-provider";
import { useColorScheme } from "../../../../lib/color-scheme";
import { computePlaybookEditProgress } from "tekkr-common/dist/model/playbook/state";
import { usePlaybook } from "../../../../pages/playbook/controller/hooks/playbook";
import { useBlueprint } from "../../../../pages/playbook/controller/hooks/blueprint";
import { DateTime } from "luxon";
import { AvatarForUser } from "../../avatar-for-user";
import { cn } from "../../../../lib/utils";
import { ChevronDown } from "lucide-react";
import { SelectPlaybookDriverDialog } from "../../../../modals/select-playbook-driver";
import { DialogTrigger } from "../../../ui/dialog";
import { UserDropdown } from "../../user-dropdown";
import { PopoverTrigger } from "../../../ui/popover";
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../../../../service/tekkr-service";
import ConfirmationDialog from "../../../../modals/confirmation-dialog";

function Stat (props: React.PropsWithChildren<{ title: string }>) {
  return <div className={"flex flex-col gap-2"}>
    <div className={"text-xs text-muted-foreground font-medium"}>{ props.title }</div>
    { props.children }
  </div>
}

const noPersonContent = <>
  <div className={"rounded-full w-6 h-6 bg-accent text-muted-foreground text-center"}>-</div>
  <span className={"text-sm text-muted-foreground"}>None</span>
</>

function Person(props: { userId?: string, isEditing: boolean }) {
  const org = useOrg();
  const user = org.users.find((u) => u.id === props.userId)!;
  return <div className={cn("flex flex-row items-center gap-2 transition-all rounded-md", props.isEditing && "px-2 py-1.5 bg-input cursor-pointer hover:bg-secondary/80")}>
    { !props.userId ? noPersonContent : <>
      <AvatarForUser user={user} className={"w-6 h-6"} />
      <span className={"text-sm"}>{ user!.name }</span>
    </> }
    <ChevronDown className={cn("w-3 h-3 transition-all opacity-65", props.isEditing ? "ms-1" : "w-0")} />
  </div>
}

interface Props {
  isEditing: boolean;
}

const confirmationBody = <>
  <p>Are you sure you want to change the owner of this playbook?</p>
  <p className={"text-sm font-medium text-muted-foreground"}>You cannot undo this change - only the new owner can transfer ownership again.</p>
</>

function DriverStat (props: { isEditing: boolean, isOwner: boolean }) {
  const { playbook, refetch: refetchPlaybook } = usePlaybook();
  const [selectDriverDialogOpen, setSelectDriverDialogOpen] = useState(false);
  const onDriverUpdated = () => {
    refetchPlaybook();
  }

  if (!props.isOwner) {
    return <Stat title={"Driver"}>
      <Person isEditing={props.isEditing} userId={playbook.contributors.driver} />
    </Stat>
  }

  return <Stat title={"Driver"}>
    <SelectPlaybookDriverDialog
        playbookId={playbook.id}
        playbookOwner={playbook.contributors.owner}
        currentDriver={playbook.contributors.driver ?? null}
        open={selectDriverDialogOpen}
        onDriverUpdated={onDriverUpdated}
        onOpenChange={setSelectDriverDialogOpen}>
      <DialogTrigger disabled={!props.isEditing}>
        <Person isEditing={props.isEditing} userId={playbook.contributors.driver} />
      </DialogTrigger>
    </SelectPlaybookDriverDialog>
  </Stat>
}

function OwnerStat (props: { isEditing: boolean, isOwner: boolean }) {
  const org = useOrg();
  const { playbook, refetch: refetchPlaybook } = usePlaybook();

  const [userIdToConfirm, setUserIdToConfirm] = useState<string | null>(null);

  const mutation = useMutation({
    mutationFn: async (userId: string) => {
      await apiClient.updatePlaybookContributors({
        params: {
          playbookId: playbook.id,
        },
        body: {
          owner: userId,
        }
      });
      await refetchPlaybook();
      setUserIdToConfirm(null);
    },
  });

  const onUserSelected = (id: string) => {
    if (id === playbook.contributors.owner) {
      // same user as before
      return;
    }
    setUserIdToConfirm(id)
  }

  if (!props.isOwner) {
    return <Stat title={"Owner"}>
      <Person isEditing={props.isEditing} userId={playbook.contributors.owner} />
    </Stat>
  }

  return <Stat title={"Owner"}>
    <ConfirmationDialog onOpenChange={() => setUserIdToConfirm(null)} fetching={mutation.isPending} open={!!userIdToConfirm} title={"Change Playbook Owner?"} body={confirmationBody} onConfirm={() => mutation.mutate(userIdToConfirm!)}>
      <UserDropdown users={org.users} onSelect={onUserSelected}>
        <PopoverTrigger disabled={!props.isEditing}>
          <Person isEditing={props.isEditing} userId={playbook.contributors.owner} />
        </PopoverTrigger>
      </UserDropdown>
    </ConfirmationDialog>
  </Stat>
}

export default function PlaybookStats(props: Props) {
  const { playbook } = usePlaybook();
  const { blueprint } = useBlueprint();
  const org = useOrg();
  const { isDark } = useColorScheme();

  const orgUser = useCurrentOrgUserOptional();
  const isOwner = !!orgUser && (orgUser.id === playbook.contributors.owner);

  const progress = computePlaybookEditProgress(blueprint, playbook?.edit);
  const isEditing = props.isEditing && isOwner;

  return (
    <div className={"animate-in slide-in-from-bottom-4 duration-300 fade-in"}>
      <hr />
      <div className={"flex flex-row justify-between mt-3"}>
        <div>
          {org.logo && <img alt={"org logo"} className={"h-10"} src={isDark ? org.logo.urlWhite : org.logo.urlBlack} />}
        </div>
        <div className={"flex flex-col items-end gap-2"}>
          <Progress
              value={progress * 100}
              className={"h-2.5 w-24"}
              color={"confirmation"}
          ></Progress>
          <div className={"text-muted-foreground"}>
            {progress === 0
                ? "Not started"
                : Math.round(progress * 100) + "%"}
          </div>
        </div>
      </div>
      <div className={cn("transition-all py-4 flex flex-row gap-8", isEditing && "gap-6")}>
        <OwnerStat isEditing={isEditing} isOwner={isOwner} />
        <DriverStat isEditing={isEditing} isOwner={isOwner} />
        <Stat title={"Created on"}>
          <span className={"text-sm"}>
            { DateTime.fromISO(playbook.createdAt).toLocaleString({ day: "numeric", month: "short", year: "numeric" }) }
          </span>
        </Stat>
      </div>
    </div>
  );
}
