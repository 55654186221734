import React, { ReactElement, useEffect, useRef } from "react";
import { Button } from "../../../components/ui/button";
import { cn } from "../../../lib/utils";
import { usePlaybookPageNavigation } from "../playbook-page";

interface Props {
    header: string;
    skipButtonText?: string;
    variant?: "plain";
    explainer?: string;
    nextButtonDisabled?: boolean;
    onNext?: () => boolean | void;
    hideNavigationButtons?: boolean;
    className?: string;
    overlayDialog?: ReactElement;
}

export function StagePageContainer (props: React.PropsWithChildren & Props) {
    const playbookPageNavigation = usePlaybookPageNavigation();

    const contentContainerRef = useRef<HTMLDivElement>(null);
    const overlayContainerRef = useRef<HTMLDivElement>(null);

    // center overlay dialog over content
    useEffect(() => {
        if (contentContainerRef.current && overlayContainerRef.current) {
            const rect = contentContainerRef.current.getBoundingClientRect();
            overlayContainerRef.current.style.left = `${rect.left}px`;
            overlayContainerRef.current.style.right = `${window.innerWidth - rect.right}px`;
        }
    }, [contentContainerRef.current, overlayContainerRef.current]);

    return <>
        <div ref={contentContainerRef} className={cn("flex flex-col gap-4", props.overlayDialog && "blur-lg pointer-events-none max-h-[70vh] overflow-hidden", props.className)}>
            <h1 className={cn("animate-in fade-in-70 duration-300", props.variant === "plain" && "mb-2")}>{ props.header }</h1>
            { props.explainer && <p className={"animate-in fade-in-70 slide-in-from-bottom-1 duration-500"}>{ props.explainer }</p>}
            { props.variant !== "plain" && <hr className={"animate-in zoom-in-50 duration-500"} /> }
            <div className={"animate-in fade-in-50 slide-in-from-top-0.5 duration-500 flex flex-col gap-3"}>
                { props.children }
            </div>
            { !props.hideNavigationButtons && <div className={"flex flex-row gap-4 justify-end"}>
                { /* <Button variant={"outline"}>{ props.skipButtonText ?? "Skip" } <ChevronLast className={"w-5 h-5 ms-2"} /></Button> */ }
                <Button
                    disabled={props.nextButtonDisabled}
                    onClick={() => {
                        if (!props.onNext || props.onNext() !== false) {
                            playbookPageNavigation?.goToNext()
                        }
                    }}
                >Next</Button>
            </div> }
        </div>
        { props.overlayDialog &&
            <div ref={overlayContainerRef} className={"absolute top-0 flex flex-col items-center left-0 right-0 justify-center bottom-0"}>
                <div className={"max-w-xl border rounded-xl shadow-black bg-background py-6 px-12"}>
                    {props.overlayDialog}
                </div>
            </div>}
    </>
}