import { PlaybookSidebarNavigation } from "../sidebar";
import { getCopyForTargetReviewTemplate } from "../../../copy/target-reviews";
import { PlaybookStage } from "tekkr-common/dist/model/playbook/enums/enums";
import { getAffectedStakeholders } from "../stages/communicate/communication";
import {
    computePlaybookTodos,
    isCompletedTodoState,
} from "tekkr-common/dist/model/playbook/stages/implementation/compute-todos";
import { PlaybookWrapper } from "tekkr-common/dist/lib/playbook-wrapper";

type NavStage = PlaybookSidebarNavigation["stages"][0];

function getTargetsNav(pw: PlaybookWrapper): NavStage {
    const steps: NavStage["steps"] = [];
    if (pw.blueprint.targetReview.expectationReviews?.length) {
        steps.push({
            step: "draft",
            name: "Draft Targets",
            icon: "edit",
            state: pw.playbook.targetsReview?.targetDraftDone ? "done" : "todo"
        });
        pw.blueprint.targetReview.expectationReviews?.forEach((review) => {
            const copy = getCopyForTargetReviewTemplate(review.copy.template);
            steps.push({
                step: "schedule-review",
                name: copy.navigationTitle,
                id: review.id,
                icon: "schedule",
                state: pw.playbook.targetsReview?.reviews[review.id]?.done ? "done" : "todo"
            });
        })
        if ((pw.blueprint.targetReview.expectationReviews?.length ?? 0) > 0) {
            steps.push({
                step: "finalize",
                name: "Finalize Targets",
                icon: "target",
                state: pw.playbook.targetsReview?.targetSettingDone ? "done" : "todo",
            });
        }
    } else {
        steps.push({
            step: "set",
            name: "Set Targets",
            icon: "target",
            state: pw.playbook.targetsReview?.targetSettingDone ? "done" : "todo",
        });
    }
    if (pw.blueprint.targetReview?.communication) {
        steps.push({
            step: "communicate",
            name: "Communicate Targets",
            icon: "communicate",
            state: pw.playbook.targetsReview?.communication?.done ? "done" : "todo",
        });
    }
    return {
        stage: PlaybookStage.targets,
        state: steps.find(s => s.state === "todo") ? "todo" : "done",
        steps,
    }
}

function getAlignmentNav(pw: PlaybookWrapper): NavStage {
    const steps: NavStage["steps"] = [];

    pw.blueprint.alignment?.playbookReviews?.forEach((review) => {
        steps.push({
            step: "schedule-review",
            id: review.id,
            name: review.navigationTitle,
            icon: "schedule",
            state: pw.playbook.alignment?.playbookReviews?.[review.id]?.done ? "done" : "todo",
        });
    });
    if (pw.blueprint.alignment?.playbookReviews?.length) {
        const done = pw.playbook.alignment?.reviewsCompleted;
        steps.push({
            step: "run-reviews",
            name: "Run Review Meetings",
            icon: "wait",
            state: done ? "done" : "todo",
            blocked: done,
        })
    }
    pw.blueprint.alignment?.roastings?.forEach((roasting) => {
        steps.push({
            step: "roasting",
            id: roasting.id,
            name: "Roasting",
            icon: "roasting",
            state: pw.playbook.alignment?.roastings?.[roasting.id]?.done ? "done" : "todo",
        });
    });

    return {
        stage: PlaybookStage.alignment,
        state: steps.find(s => s.state === "todo") ? "todo" : "done",
        steps,
    }
}

function getImplementationNav(pw: PlaybookWrapper): NavStage {
    const steps: NavStage["steps"] = [];

    const todos = pw.playbook.edit && computePlaybookTodos(pw)
    if (todos?.length) {
        const done = !todos.find(todo => {
            const status = pw.playbook.implementation?.todos?.[todo.id]?.status;
            return !isCompletedTodoState(status);
        })
        steps.push({
            icon: "todos",
            name: "Implement Changes",
            step: "todos",
            state: done ? "done" : "todo",
        });
    }

    return {
        stage: PlaybookStage.implementation,
        state: steps.find(s => s.state === "todo") ? "todo" : "done",
        steps,
    }
}

function getCommunicationNav(pw: PlaybookWrapper, currentUserId: string): NavStage {
    const steps: NavStage["steps"] = [];

    const affectedStakeholders = getAffectedStakeholders(pw, currentUserId);
    if (Object.keys(affectedStakeholders).length > 0) {
        steps.push({
            icon: "messages",
            name: "Inform Stakeholders",
            step: "inform-stakeholders",
            state: pw.playbook.communication?.informStakeholdersDone ? "done" : "todo",
        });
    }

    if (pw.blueprint.communication?.announcement) {
        steps.push({
            icon: "communicate",
            name: "Announce",
            step: "announce",
            state: pw.playbook.communication?.announcement.done ? "done" : "todo",
        });
    }

    return {
        stage: PlaybookStage.communication,
        state: steps.find(s => s.state === "todo") ? "todo" : "done",
        steps,
    }
}

export function getPlaybookNav(pw: PlaybookWrapper, currentUserId: string): PlaybookSidebarNavigation {
    const stages: NavStage[] = [];

    const pushStageIfHasSteps = (stage: NavStage) => {
        if(stage.steps && stage.steps.length > 0) {
            stages.push(stage);
        }
    }

    stages.push(getTargetsNav(pw));
    pushStageIfHasSteps(getAlignmentNav(pw));
    pushStageIfHasSteps(getImplementationNav(pw));
    pushStageIfHasSteps(getCommunicationNav(pw, currentUserId));

    if (pw.blueprint.dummyMetrics) {
        stages.push({
            stage: PlaybookStage.tracking,
            state: "todo",
            steps: [
                { icon: "graphs", name: "Metrics", state: "todo", step: "metrics" }
            ]
        });
    }

    return { stages };
}

export const testingScope = {
    getTargetsNav,
    getAlignmentNav,
    getImplementationNav,
    getCommunicationNav,
}