"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.playbookSchema = void 0;
const zod_1 = require("zod");
const edit_1 = require("./edit");
const targets_1 = require("./targets");
const state_1 = require("./state");
const alignment_1 = require("./stages/alignment");
const target_review_1 = require("./stages/target-review");
const people_groups_1 = require("./people-groups");
const implementation_1 = require("./stages/implementation");
const communication_1 = require("./stages/communication");
const contributors_1 = require("./contributors");
exports.playbookSchema = zod_1.z.strictObject({
    id: zod_1.z.string(),
    createdBy: zod_1.z.string(),
    createdAt: zod_1.z.string(),
    blueprintId: zod_1.z.string(),
    peopleGroups: people_groups_1.playbookPeopleGroupsSchema.optional(),
    state: state_1.playbookStateSchema,
    contributors: contributors_1.playbookContributorsSchema,
    edit: edit_1.playbookEditSchema.optional(),
    targets: targets_1.playbookTargetsSchema.optional(),
    targetsReview: target_review_1.playbookTargetsReviewEditSchema.optional(),
    alignment: alignment_1.playbookAlignmentEditSchema.optional(),
    implementation: implementation_1.playbookImplementationSchema.optional(),
    communication: communication_1.playbookCommunicationSchema.optional(),
});
