"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.playbookTodoBlueprintSchema = void 0;
const zod_1 = require("zod");
const blueprint_1 = require("../../segment/blueprint");
const baseTodoBlueprintSchema = zod_1.z.strictObject({
    id: zod_1.z.string(),
});
const simpleTodoBlueprintSchema = baseTodoBlueprintSchema.extend({
    type: zod_1.z.literal(blueprint_1.PlaybookTodoType.simple),
    title: zod_1.z.string(),
    description: zod_1.z.string(),
    segmentId: zod_1.z.string().optional(),
}).strict();
const meetingTodoBlueprintSchema = baseTodoBlueprintSchema.extend({
    type: zod_1.z.literal(blueprint_1.PlaybookTodoType.meeting),
    description: zod_1.z.string(),
    title: zod_1.z.string().optional().describe("uses the segment title if no title is specified"),
    segmentId: zod_1.z.string(),
}).strict();
const listTodoBlueprintSchema = baseTodoBlueprintSchema.extend({
    type: zod_1.z.literal(blueprint_1.PlaybookTodoType.list),
    segmentId: zod_1.z.string(),
    contentId: zod_1.z.string(),
});
const toolTodoBlueprintSchema = baseTodoBlueprintSchema.extend({
    type: zod_1.z.literal(blueprint_1.PlaybookTodoType.tool),
    title: zod_1.z.string(),
    description: zod_1.z.string(),
    segmentId: zod_1.z.string(),
});
exports.playbookTodoBlueprintSchema = zod_1.z.discriminatedUnion("type", [
    simpleTodoBlueprintSchema,
    meetingTodoBlueprintSchema,
    listTodoBlueprintSchema,
    toolTodoBlueprintSchema,
]);
