import { ContentSpacer } from "../common/layout";
import PlaybookCard, { PlaybookMeta } from "../../components/shared/playbook-card";
import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../service/tekkr-service";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { useCurrentOrgUser, useOrg } from "../../auth/org-provider";
import LoadingCard from "../../components/shared/loading-card";
import { Link } from "react-router-dom";
import { BookUserIcon, BuildingIcon, HandshakeIcon, LibraryIcon, MountainSnowIcon } from "lucide-react";
import { Button } from "../../components/ui/button";

const listPlaybooksQuery = (orgId: string) => ({
  queryKey: ["library-playbooks", orgId],
  queryFn: async () => {
    const response = await (await apiClient).listPlaybooks();
    if (response.status === 200) {
      return (
        response.body as ClientInferResponseBody<
          typeof apiContract.listPlaybooks,
          200
        >
      ).data;
    }
    throw new Error(`unexpected status ${response.status}`);
  },
});

function PlaybookListSection (props: {
  playbooks: PlaybookMeta[];
  title: string;
  icon: typeof BuildingIcon;
}) {
  const Icon = props.icon;
  return <div className={"flex flex-col gap-4"}>
    <p className={"flex flex-row items-center gap-2 font-semibold"}><Icon className={"w-5 h-5"} /> { props.title }</p>
    <div className={"grid md:grid-cols-3 sm:grid-cols-2 gap-3"}>
      {props.playbooks!.map((playbook) => (
          <PlaybookCard
              key={playbook.id}
              variant={"vertical"}
              state={playbook.state}
              linkTo={`/playbook/${playbook.id}`}
              data={playbook}
          />
      ))}
    </div>
  </div>
}

function ClusteredPlaybookList() {
  const org = useOrg();
  const { isPending, isError, data, error } = useQuery(
      listPlaybooksQuery(org.id),
  );
  const selfOrgUser = useCurrentOrgUser();

  if (isPending) {
    return (
        <div className={"grid md:grid-cols-3 sm:grid-cols-2 gap-3 animate-in fade-in duration-500"}>
          {Array.from({ length: 4 }).map((_, index) => (
              <LoadingCard key={index} />
        ))}
      </div>
    );
  }

  if (isError) {
    return (
      <div className={"border border-destructive rounded-lg p-4 text-center"}>
        <p className={"text-muted-foreground font-semibold"}>
          Error: {error.message}
        </p>
      </div>
    );
  }

  if (data.length === 0) {
    // empty state
    return (
      <div className={"border rounded-lg p-6 text-center mt-6 flex flex-col items-center gap-3"}>
        <MountainSnowIcon />
        <h4 className={"mb-2"}>
          Your library seems a bit empty..
        </h4>
        <p>
          Your playbook library will grow as soon as you start creating your own playbooks. The only thing you need to do: Open a playbook, hit the "Start editing" button, and they will be stored here.
        </p>
        <Link to={"/"} className={"mt-2"}>
          <Button>
            <LibraryIcon className={"w-5 h-5 me-2"} />
            Find a Playbook
          </Button>
        </Link>
      </div>
    );
  }

  const drivenByMe: PlaybookMeta[] = [];
  const ownedByMe: PlaybookMeta[] = [];
  const otherPlaybooks: PlaybookMeta[] = [];

  for (const pb of data!) {
    const isDriver = pb.contributors.driver === selfOrgUser.id;
    const isOwner = pb.contributors.owner === selfOrgUser.id;
    if (isDriver || (!pb.contributors.driver && isOwner)) {
      drivenByMe.push(pb);
    } else if (isOwner) {
      ownedByMe.push(pb);
    } else {
      otherPlaybooks.push(pb);
    }
  }

  // We can assume by this point that `isSuccess === true`
  return <div className={"flex flex-col gap-10"}>
    { !!drivenByMe.length && <PlaybookListSection playbooks={drivenByMe} title={"Driven by you"} icon={BookUserIcon} /> }
    { !!ownedByMe.length && <PlaybookListSection playbooks={ownedByMe} title={"Owned by you"} icon={HandshakeIcon} /> }
    { !!otherPlaybooks.length && <PlaybookListSection playbooks={otherPlaybooks} title={`All Playbooks at ${org.name}`} icon={BuildingIcon} /> }
  </div>
}

export default function LibraryPage() {
  useEffect(() => {
    document.title = "My Playbooks - Tekkr";
  });
  return (
    <ContentSpacer>
      <h1 className={"mt-4 mb-8 animate-in slide-in-from-top-2 fade-in-75 duration-300"}>Your Playbooks</h1>
      <ClusteredPlaybookList></ClusteredPlaybookList>
    </ContentSpacer>
  );
}
