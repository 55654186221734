import React, { useMemo } from "react";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "../components/ui/dialog";
import { Button } from "../components/ui/button";
import PeopleListSegmentContentView from "../components/shared/playbook-view/components/content/types/people-list";
import { ContentType } from "tekkr-common/dist/model/playbook/segment/content/blueprint";
import { useOrg } from "../auth/org-provider";
import { useMutation } from "@tanstack/react-query";
import Spinner from "../components/ui/spinner";
import { apiClient } from "../service/tekkr-service";

interface Props {
    playbookId: string;
    playbookOwner: string;
    currentDriver: string | null;
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onDriverUpdated?: () => Promise<void> | void;
}

export function SelectPlaybookDriverDialog(props: React.PropsWithChildren<Props>) {
    const org = useOrg();
    const owner = org.users.find((u) => u.id === props.playbookOwner)!;
    const edit = useMemo(() => ({
        selectedUsers: props.currentDriver ? [props.currentDriver] : []
    }), []);

    const mutation = useMutation({
        mutationFn: async () => {
            await apiClient.updatePlaybookContributors({
                params: {
                    playbookId: props.playbookId,
                },
                body: {
                    driver: edit.selectedUsers[0] ?? null,
                },
            });
            const sideEffectPromise = props.onDriverUpdated?.();
            if (sideEffectPromise && "then" in sideEffectPromise) {
                await sideEffectPromise;
            }
            props.onOpenChange(false);
            setTimeout(() => {
                mutation.reset();
            }, 100);
        },
    });

    return <Dialog open={props.open} onOpenChange={!mutation.isPending ? props.onOpenChange : undefined}>
        <DialogContent disableClose={mutation.isPending}>
            <DialogHeader>
                <DialogTitle>
                    Select Playbook Driver <span className={"text-xs px-2 py-0.5 bg-yellow-300 text-black rounded-md ms-2"}>WIP</span>
                </DialogTitle>
                <DialogDescription>
                    { owner.name } will remain the owner of the initiative but the driver will be in charge of implementing and configuring the playbook.
                </DialogDescription>
            </DialogHeader>

            { (mutation.isPending || mutation.isSuccess) ? <div className={"flex flex-col items-center py-6"}><Spinner /></div> : <>
                <div className={"construction-tape-bg px-2 py-6 font-medium text-sm rounded-lg"}>
                    <span className={"font-bold text-foreground"}>🚧 WIP 🚧</span>
                    <br />
                    Soon, Tekkr will help you select the right person.
                </div>
                <PeopleListSegmentContentView
                    content={{
                        id: "driver",
                        type: ContentType.peopleList,
                        title: "Driver",
                        communicate: false,
                        limit: 1,
                    }}
                    options={{
                        placeholder: "Select Driver"
                    }}
                    edit={edit}
                    isEditing={true} />
            </> }
            <DialogFooter>
                <Button disabled={mutation.isPending} onClick={() => props.onOpenChange(false)} variant={"secondary"}>Cancel</Button>
                <Button disabled={mutation.isPending} onClick={() => mutation.mutate()}>Save</Button>
            </DialogFooter>
        </DialogContent>
        {props.children}
    </Dialog>
}